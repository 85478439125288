<template>
  <div>
    <!-- Image Section -->
    <div class="relative mt-32 md:mt-32 flex justify-center items-center w-full">
      <img
        :src="images.countries"
        alt="Big Screen"
        class="hidden md:block w-4/5"
      />
      <img
        :src="images.countriesSmall"
        alt="Small Screen"
        class="block md:hidden w-full"
      />
    </div>

    <!-- Text and Button Section -->
    <div
      class="relative flex flex-col md:flex-row items-center space-x-12 w-full md:py-4 p-4 my-28 z-20"
    >
      <div
        class="relative w-full md:w-[52%] flex flex-col items-start justify-center space-y-4 mb-8 pl-12"
      >
        <!-- <Button
          buttonText="All-in-one legal tool"
          backgroundColor="bg-amber-100"
          textColor="text-[#FDAD1C]"
          borderColor="border-amber-100"
          class="bg-opacity-70 cursor-not-allowed"
        /> -->
        <h1
          ref="typedElement"
        class="text-2xl lg:text-[45px] md:text-[35px] font-semibold text-primary leading-[35px] lg:leading-[65px] md:h-[300px]  h-48 typed-container"
        ></h1>
    
        <p class="text-sm md:text-base lg:text-lg text-primary hidden md:block">
          Lawyered Up enables SMEs to create, sign, store and manage documents all on one platform.
        </p>
        <div class="flex space-x-4 md:block">
          <a
            href="https://calendly.com/lawyeredupbyvega/30min"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              buttonText="Request a demo"
              backgroundColor="bg-primary"
              borderColor="bg-primary"
            />
          </a>
          <router-link to="/contact-us">
            <Button
              buttonText="Contact us"
              backgroundColor="bg-white"
              textColor="text-primary"
              borderColor="border-primary"
            />
          </router-link>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-full md:w-[49%] mt-8 md:mt-0 flex justify-center  z-10">
        <img :src="images.screen" alt="Big Screen" class="lg:w-full md:w-full " />
      </div>
    </div>
  </div>
</template>

<style scoped>
.relative.flex.justify-center.items-center.w-full {
  position: absolute;
  top: -50px;
  z-index: 10; 
}

.relative.flex.flex-col.md\:flex-row.items-center.justify-between.w-full.my-36 {
  position: relative;
  z-index: 20; 
}

.typed-container {
  width: 100%;

  white-space: wrap;
  overflow: hidden;
  display: flex;
  align-items: center;
}
</style>


<script>
import { onMounted, ref } from "vue";
import Typed from "typed.js";
import { Icon } from "@iconify/vue";
import Button from "../UI/Button.vue";
import { images } from "@/data/images";

export default {
  components: { Icon, Button },
  setup() {
    const typedElement = ref(null);

    onMounted(() => {
      const options = {
        strings: [
          "Simplifying Legal Processes for SMEs with AI-Powered Solutions",
        ],
        typeSpeed: 50,
        backSpeed: 0,
        showCursor: false,
        loop: true,
      };

      new Typed(typedElement.value, options);
    });

    return {
      typedElement,
      images,
    };
  },
};
</script>
