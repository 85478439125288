
  <template>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
  >
    <div
      class="bg-white   rounded-lg shadow border border-neutral-200 p-6"
    >
      <header class="text-black text-xl leading-[27px] my-8 mx-4">
        Contract Assessment Completed
      </header>
      <AssessmentReport />
      <div
        class="text-amber-400 text-base mt-2 ml-4 font-['Nunito-sans']"
       
      >  
     <a href="https://calendly.com/lawyeredupbyvega/30min" target="_blank" rel="noopener noreferrer"><Button buttonText="Book a Demo"  /> </a>
        
        &nbsp; 
        <a href="https://calendly.com/lawyeredupbyvega/30min" target="_blank" rel="noopener noreferrer">
          <button  class="px-4 py-2  rounded-md justify-center items-center inline-flex cursor-pointer" style="background-color:#FFB45E ;">
    <div class=" text-sm " style="color: black;" > Get Started</div></button>
        </a>
     
      </div>
    </div>
  </div>
</template>
  <script>
import AssessmentReport from "./AssessmentReport.vue";
import Button from "../UI/Button.vue";
import { watch } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    AssessmentReport,
    Button,
  },
  props: {
    showModal: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();
      const handleDemoContractClick = () => {
      // Reload the page when the link is clicked
      router.push('./home')
      window.location.reload();
    };
    // Watch for changes in the showModal prop
    watch(
      () => props.showModal,
      (newValue) => {
        if (newValue) {
      
        } else {
    
        }
      }
    );

    const closeModal = () => {
      // Emit an event to inform the parent component to close the modal
      emit("close-modal");
    };

    return {
      closeModal,
      handleDemoContractClick
    };
  },
};
</script>
  