<template>
  <NavHeaderVue />
  <section>
    <h2
      class="mx-8 md:mx-24 lg:mx-28 my-4 text-center text-zinc-750 text-4xl md:text-5xl leading-tight tracking-wide font-semibold"
    >
      Choose the plan that is right for your business
    </h2>
    <div
      class="flex items-center space-x-4 justify-center w-full my-4 text-center text-zinc-800 md:leading-7 tracking-wide my-4"
    >
      <p>Start with a free plan to try out our platform</p>
      <p class="flex items-center justify-center space-x-2">
        <span style="text-decoration: underline">
          <a href="https://calendly.com/lawyeredupbyvega/30min" target="_blank" rel="noopener noreferrer">
          Get Started
          </a>
          </span
        ><Icon icon="solar:arrow-right-outline" />
      </p>
    </div>

    <div class="mx-4 my-8 md:mx-12 md:my-16">
      <img src="@/assets/pricing.svg" alt="" />
    </div>
  </section>
  <!-- <Clients /> -->
  <Footer />
</template>
   <script>
import NavHeaderVue from "@/components/layout/NavHeader.vue";

import Clients from "@/components/Dashboard/Clients.vue";
import Features from "@/components/Dashboard/Features.vue";
import Footer from "@/components/layout/Footer.vue";
import { Icon } from "@iconify/vue";

export default {
  components: {
    NavHeaderVue,
    Clients,
    Features,
    Footer,
    Icon,
  },
  setup() {
    return {};
  },
};
</script>
   